html,
body {
  font-family: 'Roboto',sans-serif;
  color: #535760;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.object-fit-cover {
  object-fit: cover;
}
